import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Settings from "../components/Settings";
import {
  fetchKitchens,
  fetchProfiles,
  setKitchen,
  setProfiles,
  setUnitSystem,
} from "../actions";
import { resetWasteEntry } from "~/WasteEntry/actions";

const mapStateToProps = ({
  fetchKitchens,
  fetchProfiles,
  settings: { app },
}) => ({
  fetchKitchensResult: fetchKitchens,
  fetchProfilesResult: fetchProfiles,
  appSettings: app, // todo: refactor to appSettings, remove nesting
  organizationId: app.organizationId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchKitchens,
      fetchProfiles,
      setKitchen,
      setProfiles,
      setUnitSystem,
      resetWasteEntry,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

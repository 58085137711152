import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import { Layout } from '~/Layout';
import getCrumbs from '~/Layout/crumbs';
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    overflow: 'auto',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  privacyLink: {
    marginBottom: 20,
    paddingLeft: 20,
  }
}));

const TermsOfUse = () => {
  const classes = useStyles();

  const organizationId = useSelector((state) => state.settings.app.organizationId);
 
  const env = process.env.NODE_ENV;

  const orgIdsByEnv = {
    dev: 553,
    staging: 10,
    production: 10
  };

  const organizationIdADNH = orgIdsByEnv[env];
  const isADNH = Number(organizationId) === organizationIdADNH;
  const privacyNoticeUrl = isADNH
  ? "https://privacyportal-eu-cdn.onetrust.com/8394ad8c-2b46-4837-8771-cbc69779a644/privacy-notices/bca69811-fdda-4a14-8ec8-d7e811eed2a3.json"
  : "https://privacyportal-eu-cdn.onetrust.com/8394ad8c-2b46-4837-8771-cbc69779a644/privacy-notices/460d4e4d-c60e-4740-bd94-30b6dde2e870.json";

  const boxId = isADNH
  ? 'otnotice-bca69811-fdda-4a14-8ec8-d7e811eed2a3'
  : 'otnotice-460d4e4d-c60e-4740-bd94-30b6dde2e870';

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://privacyportal-eu-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js';
    script.type = 'text/javascript';
    script.id = 'otprivacy-notice-script';
    script.onload = () => {
      if (window.OneTrust && window.OneTrust.NoticeApi) {
        window.OneTrust.NoticeApi.Initialized.then(() => {
          window.OneTrust.NoticeApi.LoadNotices([
            privacyNoticeUrl
          ]);
        });
      }
    };
    document.body.appendChild(script);    
  }, [privacyNoticeUrl]);

  return (
    <Layout crumbs={getCrumbs('home/terms')}> 
      <Box className={classes.container}>
        <Box id={boxId} className='otnotice'></Box>
      </Box>
    </Layout>
  );
};

export default TermsOfUse;

import {
  setUnit, setKitchen, setProfiles, setTabletId, setUnitSystem, resetSettings, setOrganizationId
} from '../actions';

export const initialState = {
  app: {
    unit: {},
    kitchen: {},
    profiles: [],
    tabletId: null,
    unitSystem: 'imperial',
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case resetSettings.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          kitchen: {},
          profiles: [],
          organizationId:null
        },
      };
    }

    case setUnit.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          unit: action.payload.unit,
        },
      };
    }

    case setKitchen.TYPE: {
      const { kitchen } = action.payload;
      const newApp = {
        ...state.app,
        kitchen,
        profiles: [],
      };
      if (kitchen.organization_id !== undefined && kitchen.organization_id !== null) {
        newApp.organizationId = kitchen.organization_id;
      }
      return {
        ...state,
        app: newApp,
      };
    }
    
    case setProfiles.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          profiles: action.payload.profiles,
        },
      };
    }
    case setTabletId.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          tabletId: action.payload.tabletId,
        },
      };
    }
    case setOrganizationId.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          organizationId: action.payload.organizationId,
        },
      };
    }
    case setUnitSystem.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          unitSystem: action.payload.unitSystem,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
